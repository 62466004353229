<template>
  <div
    v-if="openFournisseur"
    :class="`modal ${
      !openFournisseur && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openFournisseur = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">
            Disponibilités dépôts pour {{ Article.Ref_fournisseur }}
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openFournisseur = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="w-full bg-white flex flex-col">
            <div class="flex w-full justify-end"></div>
            <div class="flex flex-col">
              <div
                class="flex flex-row items-center justify-between animate-pulse"
                v-show="loadingFournisseur"
              >
                <div>Recherche fournisseur...</div>
              </div>

              <div
                class="m-4 relative overflow-x-auto shadow-md sm:rounded-lg"
                v-show="!loadingFournisseur"
              >
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500"
                >
                  <thead class="text-xs text-white uppercase bg-blue-500">
                    <tr>
                      <th scope="col" class="px-6 py-3">Dépôt</th>
                      <th scope="col" class="px-6 py-3">Prix Net Achat</th>
                      <th scope="col" class="px-6 py-3">Dispo</th>
                      <th scope="col" class="px-6 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(fourn, indexFourn) in fournisseurTab"
                      :key="indexFourn"
                      :class="
                        indexFourn % 2 === 0
                          ? 'odd:bg-white  even:bg-gray-50 border-b '
                          : 'odd:bg-white  even:bg-gray-50 border-b '
                      "
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {{ fourn.depot_name }}
                      </th>
                      <td class="px-6 py-4">
                        <div class="font-bold text-black">
                          {{ parseFloat(fourn.prix.prix_net).toFixed(2) }}
                          €
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div v-if="fourn.loadingDispo">...</div>
                        <div v-else>
                          <div
                            v-if="
                              fourn.dispo.qtyJour + fourn.dispo.qtySoir >=
                              fourn.quantity
                            "
                            class="flex flex-row items-center space-x-2 text-blue-500"
                          >
                            <div>
                              <span class="material-icons-round">
                                check_circle
                              </span>
                            </div>
                            <div>
                              <div
                                v-if="
                                  fourn.Prefixe_tarif != 'Vhi' &&
                                  fourn.Prefixe_tarif != 'vwz'
                                "
                              >
                                Dispo J+1
                              </div>
                              <div v-else-if="fourn.Prefixe_tarif == 'aps'">
                                Dispo J+1 commande avant 16h
                              </div>
                              <div
                                class="flex flex-col space-y-1"
                                v-else-if="fourn.Prefixe_tarif == 'vwz'"
                              >
                                <div>Dispo J+2</div>
                                <div>Frais supplémentaires si Gabarit > 2m</div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="flex flex-row items-center space-x-2 text-orange-500"
                          >
                            <div>
                              <span class="material-icons-round"> help </span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="fourn.article.fraisdeport"
                          class="flex flex-row space-x-1 text-sm items-center font-bold text-blue-500 text-xs"
                        >
                          <span>Frais de port:</span>
                          <span>{{ fourn.article.fraisdeport }}</span>
                          <span>€</span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-row">
                          <input
                            type="number"
                            v-model="fourn.quantity"
                            @change="ajustDispoFournisseur(fourn, indexFourn)"
                            class="w-8 border border-gray-200 rounded"
                          />
                          <button
                            class="material-icons-round text-bluevk"
                            @click="
                              handlerArticle(
                                {
                                  article: fourn.article,
                                  stockData: [],
                                },
                                fourn.quantity
                              )
                            "
                          >
                            add_shopping_cart
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row w-full">
    <div class="w-full">
      <div
        @click="displayFournisseur()"
        class="flex flex-row items-center justify-around cursor-pointer"
      >
        <div class="text-xs">{{ user.plateform }}</div>
        <div class="flex flex-col items-center space-x-2">
          <div v-if="Stock.stock >= quantity && quantity > 0">
            <span class="material-icons-round text-2xl text-green-500">
              check_circle
            </span>
          </div>
          <div v-else-if="stateFilliale">
            <span
              :style="{
                backgroundColor: textcolorFilliale,
                color: bgcolorFilliale,
              }"
              class="material-icons-round text-2xl rounded-full"
            >
              check_circle
            </span>
          </div>
          <div v-else>
            <span
              v-if="!stateFournisseur"
              class="material-icons-round text-2xl text-orange-500"
            >
              help
            </span>
            <span
              v-else-if="stateFournisseur"
              :class="[
                'material-icons-round text-2xl',
                Article.Prefixe_tarif == 'vwz'
                  ? 'icon-bicolor mx-auto'
                  : 'text-blue-500',
              ]"
            >
              check_circle
            </span>
          </div>
          <div
            v-if="stateFournisseur && Article.Prefixe_tarif == 'vwz'"
            class="text-xs text-blue-500"
          >
            Frais si Gabarit > 2m
          </div>
          <!-- <div v-else-if="Stock.state == 'En filliale'">
            <span class="material-icons-round text-2xl text-blue-500">
              check_circle
            </span>
          </div> -->
        </div>
      </div>
    </div>

    <!-- <div>Tendance de mouvement</div> -->
  </div>
</template>
<script>
import axios from "axios";
import { getFournisseurList } from "@/hooks/articles/articles.ts";
import { mapActions, mapGetters } from "vuex";
import { getStockFournisseur, checkStock } from "@/hooks/stock/stock.ts";
import { prepareArticleClient } from "@/hooks/searchEngine/article.ts";
import { useToast } from "vue-toastification";
export default {
  name: "componentStock",
  data() {
    return {
      fournisseurTab: [],
      loadingFournisseur: false,
      loadingCommandeFournisseur: false,
      loadingDispoFournisseur: false,
      stateFournisseur: null,
      colorFournisseur: null,
      stateFilliale: null,
      bgcolorFilliale: null,
      textcolorFilliale: null,
      Stock: {},
      openFournisseur: false,
      filialeTab: [],
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "tabClient", "actionArticle"]),
  },
  props: {
    Article: { type: Object, default: () => ({}) },
    quantity: { type: Number, default: 1 },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
    ]),
    async displayFournisseur() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      this.fournisseurTab = [];
      this.loadingFournisseur = true;
      this.openFournisseur = true;
      this.fournisseurTab = await getFournisseurList(
        plateform_mere,
        this.Article,
        this.user.proprietaire,
        this.tabClient.Group.Name,
        this.quantity
      );
      this.loadingFournisseur = false;
      for (const fourn of this.fournisseurTab) {
        fourn.dispo = await getStockFournisseur(
          fourn.article,
          this.Article.Ref_fournisseur,
          this.Article.Code_marque,
          fourn.Prefixe_tarif,
          this.plateform.grossierID,
          this.quantity,
          this.plateform.grossierID,
          plateform_mere
        );
        fourn.article["fraisdeport"] = fourn.dispo.fraisdeport;
        fourn.loadingDispo = false;
      }
    },
    async ajustDispoFournisseur(fourn, index) {
      this.fournisseurTab[index].loadingDispo = true;
      this.fournisseurTab[index].dispo = await getStockFournisseur(
        fourn.article,
        this.Article.Ref_fournisseur,
        this.Article.Code_marque,
        fourn.Prefixe_tarif,
        this.plateform.grossierID,
        fourn.quantity,
        this.plateform.grossierID,
        this.user.proprietaire
      );
      this.fournisseurTab[index].loadingDispo = false;
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.tabClient.dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
      } else {
        if (
          this.tabClient.numcomptedasilva &&
          this.tabClient.numcomptedasilva != ""
        ) {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    async handlerArticle(structure, qtyToPanier) {
      var existArticle = false;
      //   await MisePanier(this.savedSearch, structure.article.Ref_fournisseur);
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) +
            parseInt(qtyToPanier);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticleClient(
          structure,
          plateform_mere,
          this.user.proprietaire,
          this.tabClient.Group.Name,
          parseInt(qtyToPanier)
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);
        if (structure.article.fraisdeport) {
          if (structure.article.fraisdeport > 0) {
            this.addactionArticle({
              _id: "fraisdeport",
              Ref_fournisseur: "Frais de port",
              Prix_euro: structure.article.fraisdeport,
              Prix_vente: structure.article.fraisdeport,
              PrixFournisseurType: "prixnet",
              disabled: true,
              quantity: 1,
              remise: 0,
              prixtotal: structure.article.fraisdeport,
              prixfinal: structure.article.fraisdeport,
              Code_marque: structure.article.Code_marque,
              Description: "Frais de port dépannage fournisseur",
              commentaire: "",
            });
          }
        }

        //TODO a supprimer
        this.refClient = this.noteDocument;
      }
      this.success();
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    let chkStock = await checkStock(
      this.user.plateform,
      this.Article.Ref_fournisseur,
      this.Article._id,
      this.Article.Code_marque,
      this.Article.Prefixe_tarif,
      this.quantity,
      this.user._id
    );
    if (chkStock.state === "available") {
      this.Stock = chkStock.data;
      this.Stock.state = "available";
      this.$emit("stockstate", "En stock");
    }
    if (chkStock.state === "plateform + subsidiary") {
      this.Stock = chkStock.data.plateform;
      this.Stock.state = "available";
      this.filialeTab = chkStock.data.filiale.division;
      for (const fil of chkStock.data.filiale.division) {
        console.log(chkStock.data);
        if (
          fil.stock + this.Stock.stock >= this.quantity &&
          this.quantity > 0
        ) {
          this.stateFilliale = true;
          this.bgcolorFilliale = "green";
          this.textcolorFilliale = "white";
          this.$emit("stockstate", "En stock");
          break;
        } else {
          this.stateFilliale = false;
        }
      }
    } else if (chkStock.state === "subsidiary") {
      this.Stock = chkStock.data.filiale;
      this.Stock.state = "available";
      this.filialeTab = chkStock.data.filiale.division;
      for (const fil of chkStock.data.filiale.division) {
        if (fil.stock >= this.quantity && this.quantity > 0) {
          this.stateFilliale = true;
          this.bgcolorFilliale = "green";
          this.textcolorFilliale = "white";
          this.$emit("stockstate", "En stock");
          break;
        } else {
          this.stateFilliale = false;
        }
      }
    } else if (chkStock.state === "not available") {
      this.Stock = chkStock.data;
      this.Stock.state = "not available";
    } else if (chkStock.state === "not available + no subsidiary") {
      this.Stock = chkStock.data.plateform;
      this.Stock.state = "not available";
    }
  },
  async updated() {
    if (this.Stock.state === "not available") {
      // for (const fil of this.Stock.filliale) {
      //   if (fil.stock) {
      //     if (fil.stock.stock + this.Stock.stock >= this.quantity) {
      //       this.stateFilliale = true;
      //       this.bgcolorFilliale = fil.style.bgColor;
      //       this.textcolorFilliale = fil.style.textColor;
      //       break;
      //     } else {
      //       this.stateFilliale = false;
      //     }
      //   }
      // }
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      if (!this.stateFilliale) {
        const dispoFournisseur = await getStockFournisseur(
          this.Article,
          this.Article.Ref_fournisseur,
          this.Article.Code_marque,
          this.Article.Prefixe_tarif,
          this.plateform.grossierID,
          this.quantity,
          this.plateform.grossierID,
          plateform_mere
        );
        this.stateFournisseur = dispoFournisseur.dispoFournisseur;
        if (dispoFournisseur.dispoFournisseur) {
          this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
          this.$emit("stockstate", "En fournisseur");
        } else {
          this.$emit("fraisdeportfournisseur", dispoFournisseur.fraisdeport);
          this.$emit("stockstate", "En rupture");
        }

        if (dispoFournisseur.color) {
          this.colorFournisseur = dispoFournisseur.color;
        }
      }
    } else {
      this.$emit("stockstate", "En stock");
      this.$emit("fraisdeportfournisseur", 0);
    }
  },
};
</script>
<style scoped>
.icon-bicolor {
  display: inline-block;
  background: linear-gradient(115deg, #22c55e 50%, #f97316 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
