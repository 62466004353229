<template>
  <div>
    <!-- Modal -->
    <div
      v-if="openShowModal"
      class="fixed inset-0 bg-gray-500 bg-opacity-60 flex justify-center items-center z-40"
    >
      <div
        class="bg-white p-6 rounded-lg w-[34rem] h-[19rem] relative shadow-lg"
      >
        <!-- Fermer Button Line -->
        <div class="absolute top-2 right-2 flex items-center space-x-2">
          <!-- Fermer Button -->
          <button
            @click="closeModal"
            class="text-gray-500 text-sm hover:bg-gray-100 rounded-full px-3 py-1"
          >
            X
          </button>
        </div>

        <!-- Modal Content -->
        <div class="flex justify-center mt-6">
          <img src="@/assets/warning-rgb.png" alt="My Icon" class="h-[6rem]" />
        </div>
        <p class="text-center text-black font-medium text-[18px] mt-8 mb-4">
          Êtes-vous sûr de vouloir demander le retour ?
        </p>
        <!-- Line -->
        <div class="ml-6 h-[1.5px] w-[28rem] bg-gray-200 shadow-lg"></div>
        <!-- Buttons -->
        <div class="flex justify-end mt-8">
          <button
            @click="closeModal"
            class="bg-red-600 hover:bg-red-400 text-white px-3 py-1 rounded-md"
          >
            Fermer
          </button>
          <button
            @click="confirmeModal"
            class="bg-green-600 hover:bg-green-400 text-white px-3 py-1 ml-3 rounded-md"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-between">
    <button
      @click="$router.back()"
      class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
    >
      <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
      <span>retour</span>
    </button>
    <button
      class="px-2 py-2 ml-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      @click="openModal()"
      v-if="panierRetour.length > 0"
      :disabled="loadingSendRetour"
    >
      Demander le retour
    </button>
  </div>

  <div class="mt-4">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
      >
        <table class="min-w-full" style="height: 70vh">
          <thead class="tableHEADTR">
            <tr>
              <th
                class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Réf.
              </th>
              <th
                class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Description / Marque
              </th>
              <th
                class="w-32 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Raison du retour / Date BL
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                P.V.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Px final
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Total
              </th>
            </tr>
          </thead>

          <tbody class="bg-white tableBODY" style="height: 70vh">
            <tr
              v-for="(articleAct, index) in panierRetour"
              :key="index"
              class="tableHEADTR"
            >
              <!-- Référence -->
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH w-44"
              >
                {{ articleAct.Ref_fournisseur }}
              </td>

              <!-- Description / Marque -->
              <td
                class="w-80 px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-sm leading-5 whitespace-normal w-fit">
                  {{ articleAct.Description }}
                </div>
                <div class="text-xs leading-5 text-gray-900">
                  {{ articleAct.Code_marque }}
                </div>
              </td>

              <!-- Quantité -->
              <td
                class="w-32 px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex justify-between items-center space-x-1">
                  <input
                    class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                    v-model="articleAct.quantity"
                    style="width: 50%"
                    type="number"
                    disabled
                  />

                  <button
                    class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                    @click="deleteArticle(index)"
                  >
                    <span
                      class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                      style="font-size: small"
                    >
                      clear
                    </span>
                  </button>
                </div>
              </td>

              <!-- Raison retour -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-sm leading-5 whitespace-normal">
                  <select
                    v-model="articleAct.raisonRetour"
                    class="border border-2 rounded-md sm:w-10 md:w-12 lg:w-20 xl:w-32 2xl:w-40 outline-2 focus:outline-sky-300"
                    :class="{
                      'red-border': articleAct.raisonRetour === '',
                    }"
                    @change="savePanier()"
                  >
                    <option
                      v-for="option in tabRaisonRetour"
                      :key="option.id"
                      :value="option.name"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <input
                    v-if="articleAct.raisonRetour == 'Autre'"
                    class="border border-2 rounded-md sm:w-10 md:w-12 lg:w-20 xl:w-32 2xl:w-40 outline-2 focus:outline-sky-300"
                    v-model="articleAct.raisonRetourAutre"
                    placeholder="Précisez la raison"
                    :class="{
                      'red-border': articleAct.raisonRetourAutre === '',
                    }"
                  />
                </div>
                <div
                  class="flex flex-col text-xs leading-5 text-gray-900 sm:text-xs md:text-md sm:w-10 md:w-12"
                >
                  <p>{{ articleAct.typeDoc }} n° {{ articleAct.numBL }}</p>
                  <p>Date : {{ getFRDate(articleAct.dateBL) }}</p>
                </div>
              </td>

              <!-- prix vente -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                {{ articleAct.Prix_euro }}
                €
              </td>

              <!-- remise / prix net -->
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex text-sm leading-5 text-gray-900">
                  <div class="flex">
                    <p v-if="articleAct.PrixFournisseurType != 'prixnet'">
                      {{ financial(articleAct.remise) }} %
                    </p>
                    <p v-else>{{ articleAct.PrixFournisseurType }}</p>
                  </div>
                </div>
              </td>

              <!-- prix final -->
              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                  <p v-if="articleAct.PrixFournisseurType != 'prixnet'">
                    {{
                      financial(
                        articleAct.Prix_euro -
                          articleAct.Prix_euro * (articleAct.remise / 100)
                      )
                    }}
                  </p>
                  <p v-else>
                    {{ financial(articleAct.Prix_vente) }}
                  </p>

                  <p>€</p>
                </div>
              </td>

              <!-- total -->
              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                  <span v-if="articleAct.PrixFournisseurType != 'prixnet'">
                    {{
                      financial(
                        (articleAct.Prix_euro -
                          articleAct.Prix_euro * (articleAct.remise / 100)) *
                          articleAct.quantity
                      )
                    }}
                  </span>
                  <span v-else>
                    {{ financial(articleAct.Prix_vente * articleAct.quantity) }}
                  </span>
                  <span>€</span>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="tableHEADTR">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <button
                  class="flex justify-between items-center p-2 text-gray-600 border rounded-md hover:bg-red-200"
                  @click="clearPanier()"
                >
                  <span class="material-icons-outlined" style="color: red"
                    >delete_forever</span
                  >
                  <button class="text-xs mx-4">Vider</button>
                </button>
              </th>

              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 w-28 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="relative flex flex-col" v-if="allPanier">
                  <span>Commentaire :</span>
                  <div
                    class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                    @click="(allPanier.Note = ''), savePanier()"
                  >
                    <span
                      style="font-size: 15px"
                      class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent"
                      >cancel</span
                    >
                  </div>
                  <textarea
                    @change="savePanier()"
                    class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                    v-model="allPanier.Note"
                    type="text"
                  ></textarea>
                </div>
              </th>
              <th
                class="w-1 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-86 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-1 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                {{ setQuantity }} pcs
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 m-auto py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="hover:bg-gray-100 cursor-pointer rounded-md">
                  <div class="flex flex-col text-sm leading-5 text-gray-900">
                    Total HT :
                    {{ financial(setTotal) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{ financial(setTotalTTC) }} €
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      allPanier: {},
      panierRetour: [],
      tabRaisonRetour: [
        { id: 0, name: "Pièce incorrecte" },
        { id: 1, name: "Dommages pendant le transport" },
        { id: 2, name: "Article défectueux" },
        { id: 3, name: "Commande en double" },
        { id: 4, name: "Consigne" },
        { id: 5, name: "Non compatible" },
        { id: 6, name: "Pièce manquante" },
        { id: 7, name: "Mauvaise quantité" },
        { id: 8, name: "Retard de livraison" },
        { id: 9, name: "Article endommagé après utilisation" },
        { id: 10, name: "Changement d'avis" },
        { id: 11, name: "Autre" },
      ],
      loadingSendRetour: false,
      openShowModal: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      this.panierRetour.forEach((element, index) => {
        if (element.PrixFournisseurType == "prixnet") {
          totalRemise = parseFloat(
            parseFloat(element.Prix_vente * element.quantity).toFixed(2)
          );
        } else {
          totalRemise = parseFloat(
            parseFloat(
              (element.Prix_euro - element.Prix_euro * (element.remise / 100)) *
                element.quantity
            ).toFixed(2)
          );
        }
        total = total + totalRemise;
      });
      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    setQuantity() {
      var qty = 0;
      this.panierRetour.forEach((element) => {
        qty = qty + parseFloat(-element.quantity);
      });
      return qty;
    },
    checkInputsRaisonRetour() {
      var line = false;
      this.panierRetour.forEach((element, i) => {
        if (
          !element.raisonRetour ||
          element.raisonRetour === "" ||
          (element.raisonRetour == "Autre" && element.raisonRetourAutre === "")
        ) {
          line = true;
        }
      });
      return line;
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getPanier() {
      axios
        .post(`${process.env.VUE_APP_API}/panierRetour/getByClient`, {
          plateform: this.user.plateform, // plateform
          Client: this.user.proprietaire, // Client
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.allPanier = response.data[0];
            this.panierRetour = response.data[0].itemToReturn;
          } else {
            this.allPanier = [];
            this.panierRetour = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePanier() {
      axios
        .put(
          `${process.env.VUE_APP_API}/panierRetour/${this.allPanier._id}`,
          this.allPanier
        )
        .then(() => {
          this.getPanier();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removePanier() {
      axios
        .put(
          `${process.env.VUE_APP_API}/panierRetour/removepanierRetour/${this.allPanier._id}`
        )
        .then(() => {
          this.getPanier();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteArticle(index) {
      this.panierRetour.splice(index, 1);
      this.savePanier();
    },
    clearPanier() {
      this.allPanier.itemToReturn = [];
      this.panierRetour = [];
      this.savePanier();
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    async sendRetour() {
      this.loadingSendRetour = true;
      if (!this.checkInputsRaisonRetour) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
        var yyyy = today.getFullYear();
        var hour = today.getHours();
        var seconds = today.getSeconds();
        var minutes = today.getMinutes();
        today =
          dd +
          "/" +
          mm +
          "/" +
          yyyy +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds;

        var tabConsigne = [];
        var tabRetour = [];

        this.panierRetour.forEach((element, index) => {
          if (
            element.Ref_fournisseur.startsWith("C :") &&
            !this.panierRetour[index - 1]
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.panierRetour[index - 1] &&
            !this.panierRetour[index - 1].Montant_consigne
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.panierRetour[index - 1] &&
            this.panierRetour[index - 1].Montant_consigne &&
            this.panierRetour[index - 1].Ref_fournisseur !=
              element.Ref_fournisseur.substr(4)
          ) {
            tabConsigne.push(element);
          } else {
            tabRetour.push(element);
          }
        });

        var pdfinfoRetour = [];
        var prixRemiseRetour = 0.0;
        var totalRetour = 0.0;
        var totalRemiseRetour = 0.0;

        // Retour pièce
        if (tabRetour.length > 0) {
          tabRetour.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseRetour = parseFloat(element.Prix_vente);
            } else {
              prixRemiseRetour =
                element.Prix_euro -
                element.Prix_euro * (this.panierRetour[index].remise / 100);
            }
            pdfinfoRetour[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: this.panierRetour[index].quantity,
              remise: this.panierRetour[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseRetour,
              total: prixRemiseRetour * this.panierRetour[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabRetour
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseRetour = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseRetour = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalRetour = totalRetour + totalRemiseRetour;
          });

          await axios.post(`${process.env.VUE_APP_API}/retour`, {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
            Name: this.user.username,
            // Date: today,
            clientInfo: this.allPanier.clientInfo,
            requestedArticles: tabRetour,
            Articles: tabRetour,
            pdfData: pdfinfoRetour,
            requestedPdfData: pdfinfoRetour,
            reglement: [],
            Note: this.allPanier.Note,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalRetour,
            requestedTotalretour: totalRetour,
            PourcentageStatus: "10",
            retourstate: [true, false, false, false],
          });
        }

        var pdfinfoConsigne = [];
        var prixRemiseConsigne = 0.0;
        var totalConsigne = 0.0;
        var totalRemiseConsigne = 0.0;

        // Consigne
        if (tabConsigne.length > 0) {
          tabConsigne.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseConsigne = parseFloat(element.Prix_vente);
            } else {
              prixRemiseConsigne =
                element.Prix_euro -
                element.Prix_euro * (tabConsigne[index].remise / 100);
            }
            pdfinfoConsigne[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: tabConsigne[index].quantity,
              remise: tabConsigne[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseConsigne,
              total: prixRemiseConsigne * tabConsigne[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabConsgine
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseConsigne = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseConsigne = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalConsigne = totalConsigne + totalRemiseConsigne;
          });

          await axios.post(`${process.env.VUE_APP_API}/retourConsigne`, {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
            Name: this.user.username,
            // Date: today,
            clientInfo: this.allPanier.clientInfo,
            requestedArticles: tabConsigne,
            Articles: tabConsigne,
            requestedPdfData: pdfinfoConsigne,
            pdfData: pdfinfoConsigne,
            reglement: [],
            Note: this.allPanier.Note,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalConsigne,
            requestedTotalretour: totalConsigne,
            PourcentageStatus: "10",
            retourstate: [true, false, false, false],
          });
        }

        await this.ajustQuantityReturned(this.panierRetour);
        this.removePanier();
        this.toast.success("Demande de retour envoyé !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        //   let pdfData = [
        //     {
        //       description: articleData.Description,
        //       ref: articleData.Ref_fournisseur,
        //       quantite: -this.qtyReturned[indexRetour],
        //       remise: articleData.remise,
        //       commentaire: "",
        //       remiseSupp: 0,
        //       prixU:
        //         articleData.Prix_euro -
        //         articleData.Prix_euro * (articleData.remise / 100),
        //       total: articleData.Prix_euro * -this.qtyReturned[indexRetour],
        //     },
        //   ];
        // let prixTotal;
        // if (articleData.PrixFournisseurType == "prixnet") {
        //   prixTotal = articleData.Prix_vente * -this.qtyReturned[indexRetour];
        // } else {
        //   prixTotal =
        //     (articleData.Prix_euro -
        //       articleData.Prix_euro * (articleData.remise / 100)) *
        //     -this.qtyReturned[indexRetour];
        // }

        // if (
        //   this.qtyReturned[indexRetour] >
        //     articleRetourData.quantity - articleRetourData.quantityReturned ||
        //   this.qtyReturned[indexRetour] < 0
        // ) {
        //   this.showErrorMsgReturn = true;
        // } else if (articleRetourData.Ref_fournisseur.startsWith("C :")) {
        //   this.showErrorMsgReturn = false;
        //   articleData.quantity = -this.qtyReturned[indexRetour];
        // await this.ajustQuantityReturned(this.panierRetour.itemToReturn);
        // this.$router.push("/retour");
        // } else {
        //   this.showErrorMsgReturn = false;
        //   articleData.quantity = -this.qtyReturned[indexRetour];
        //   await axios.post(`${process.env.VUE_APP_API}/retour`, {
        //     plateform: this.user.plateform,
        //     Client: this.user.proprietaire,
        //     Name: this.user.username,
        //     Date: today,
        //     clientInfo: this.retourSelected.clientInfo,
        //     Articles: [articleData],
        //     pdfData: pdfinfo,
        //     NumeroBl: parseInt(this.retourSelected.Numero),
        //     reglement: [],
        //     Note: this.retourSelected.Note,
        //     type: this.retourSelected.type,
        //     status: "Retour demandé",
        //     Archived: false,
        //     Totalretour: prixTotal,
        //     PourcentageStatus: "10",
        //     retourstate: [true, false, false, false],
        //   });
        //   await this.ajustQuantityReturned([articleData]);
        //   this.$router.push("/retour");
        // }
      } else {
        this.toast.error("Remplissez les champs !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.loadingSendRetour = false;
    },
    async sendRetourWithConsigne(
      articleRetourData,
      articleConsigneData,
      indexRetour
    ) {
      let articleData = articleRetourData;
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      let pdfData = [
        {
          description: articleData.Description,
          ref: articleData.Ref_fournisseur,
          quantite: -this.qtyReturned[indexRetour],
          remise: articleData.remise,
          commentaire:
            articleData.typeDoc +
            " n°" +
            articleData.numBL +
            " du " +
            this.getFRDate(articleData.dateBL),
          remiseSupp: 0,
          prixU:
            articleData.Prix_euro -
            articleData.Prix_euro * (articleData.remise / 100),
          total: articleData.Prix_euro * -this.qtyReturned[indexRetour],
        },
        {
          description: articleConsigneData.Description,
          ref: articleConsigneData.Ref_fournisseur,
          quantite: -this.qtyReturned[indexRetour],
          remise: articleConsigneData.remise,
          commentaire:
            articleConsigneData.typeDoc +
            " n°" +
            articleConsigneData.numBL +
            " du " +
            this.getFRDate(articleConsigneData.dateBL),
          remiseSupp: 0,
          prixU:
            articleConsigneData.Prix_euro -
            articleConsigneData.Prix_euro * (articleConsigneData.remise / 100),
          total: articleConsigneData.Prix_euro * -this.qtyReturned[indexRetour],
        },
      ];
      let prixTotal;
      if (articleData.PrixFournisseurType == "prixnet") {
        prixTotal =
          articleData.Prix_vente * -this.qtyReturned[indexRetour] +
          articleConsigneData.Prix_euro * -this.qtyReturned[indexRetour];
      } else {
        prixTotal =
          (articleData.Prix_euro -
            articleData.Prix_euro * (articleData.remise / 100)) *
            -this.qtyReturned[indexRetour] +
          articleConsigneData.Prix_euro * -this.qtyReturned[indexRetour];
      }

      if (
        this.qtyReturned[indexRetour] >
          articleRetourData.quantity - articleRetourData.quantityReturned ||
        this.qtyReturned[indexRetour] < 0
      ) {
        this.showErrorMsgReturn = true;
      } else if (articleRetourData.Ref_fournisseur.startsWith("C :")) {
        this.showErrorMsgReturn = false;
        articleData.quantity = -this.qtyReturned[indexRetour];
        articleConsigneData.quantity = -this.qtyReturned[indexRetour];
        await axios.post(`${process.env.VUE_APP_API}/retourConsigne`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
          Name: this.user.username,
          // Date: today,
          clientInfo: this.retourSelected.clientInfo,
          requestedArticles: [articleData, articleConsigneData],
          Articles: [articleData, articleConsigneData],
          requestedPdfData: pdfData,
          pdfData: pdfData,
          NumeroBl: parseInt(this.retourSelected.Numero),
          reglement: [],
          Note: this.retourSelected.Note,
          type: this.retourSelected.type,
          status: "Retour demandé",
          Archived: false,
          Totalretour: prixTotal,
          requestedTotalretour: prixTotal,
          PourcentageStatus: "10",
          retourstate: [true, false, false, false],
        });
        await this.ajustQuantityReturned([articleData, articleConsigneData]);
        this.$router.push("/retour");
      } else {
        this.showErrorMsgReturn = false;
        articleData.quantity = -this.qtyReturned[indexRetour];
        articleConsigneData.quantity = -this.qtyReturned[indexRetour];
        await axios.post(`${process.env.VUE_APP_API}/retour`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
          Name: this.user.username,
          // Date: today,
          clientInfo: this.retourSelected.clientInfo,
          requestedArticles: [articleData, articleConsigneData],
          Articles: [articleData, articleConsigneData],
          requestedPdfData: pdfData,
          pdfData: pdfData,
          NumeroBl: parseInt(this.retourSelected.Numero),
          reglement: [],
          Note: this.retourSelected.Note,
          type: this.retourSelected.type,
          status: "Retour demandé",
          Archived: false,
          Totalretour: prixTotal,
          requestedTotalretour: prixTotal,
          PourcentageStatus: "10",
          retourstate: [true, false, false, false],
        });
        await this.ajustQuantityReturned([articleData, articleConsigneData]);
        this.$router.push("/retour");
      }
    },
    async ajustQuantityReturned(article) {
      // if (this.retourSelected.type == "BL") {
      for (const [indexA, element] of article.entries()) {
        await axios
          .put(`${process.env.VUE_APP_API}/bdl/return/${element.idBL}`, {
            _id: element._id,
            Ref_fournisseur: element.Ref_fournisseur,
            quantityReturned: -element.quantity,
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // }
    },
    openModal() {
      this.getPanier();
      this.openShowModal = true;
    },
    closeModal() {
      this.openShowModal = false;
    },
    confirmeModal() {
      this.sendRetour();
      this.closeModal();
    },
  },
  mounted() {
    this.getPanier();
  },
};
</script>

<style scoped>
.red-border {
  border: 1px solid red;
}
</style>
