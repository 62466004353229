<template>
  <div
    v-if="openModalArticle"
    :class="`modal ${
      !openModalArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <article-info :key="articleInfo_componentKey" />
      </div>
    </div>
  </div>

  <!-- BODY -->
  <div class="flex flex-col space-y-1">
    <div class="flex flex-row justify-between items-center">
      <button
        id="retour"
        @click="$router.back()"
        class="bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
        style="color: #5976e8"
      >
        <span class="material-icons-outlined text-sm m-auto">
          arrow_back_ios
        </span>
        <span class="m-auto">retour</span>
      </button>
    </div>
    <!-- <div class="flex flex-row space-x-2 p-1 items-center h-85"> -->
    <div class="flex space-x-6 w-full h-full flex p-2">
      <!-- filtrer par marque -->
      <div
        class="w-40 h-fit bg-white shadow rounded-md p-2 overflow-y-auto space-y-3"
      >
        <div class="flex flex-col space-y-3">
          <span class="font-semibold text-lg text-blue-800">Marques</span>

          <div class="flex flex-col ml-2 mt-1">
            <side-loading v-show="isLoading" />
            <div
              class="flex flex-row space-x-2 items-center"
              v-for="(bd, indexBrand) in brandList"
              :key="indexBrand"
            >
              <input
                type="checkbox"
                class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                v-model="brandSelected"
                :value="bd"
                @change="sortByBrand()"
              />
              <span class="text-sm">{{ bd }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-3">
          <span class="font-semibold text-lg text-blue-800">Familles</span>

          <div class="flex flex-col ml-2 mt-1">
            <side-loading v-show="isLoading" />
            <div
              class="flex flex-row space-x-2 items-center"
              v-for="(gp, indexGP) in groupList"
              :key="indexGP"
            >
              <input
                type="checkbox"
                class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                v-model="groupSelected"
                :value="gp"
                @change="sortByGroup()"
              />
              <span class="text-xs">{{ gp }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-3">
          <span class="font-semibold text-lg text-blue-800">Constructeurs</span>

          <div class="flex flex-col ml-2 mt-1">
            <side-loading v-show="isLoading" />
            <div
              class="flex flex-col space-y-1"
              v-for="(constructeur, indexConst) in tabConstructeur"
              :key="indexConst"
            >
              <span
                class="text-xs cursor-pointer hover:underline"
                @click="
                  showConstructeur[indexConst] = !showConstructeur[indexConst]
                "
                >{{ constructeur.mfrName }}</span
              >
              <div
                class="text-xs flex flex-col space-y-1 ml-2"
                v-show="showConstructeur[indexConst]"
              >
                <span
                  v-for="(oeConst, indexOeConst) in constructeur.articleNumber"
                  :key="indexOeConst"
                  class="text-blue-500 hover:underline cursor-pointer"
                  @click="searchRefFournisseur({ Ref_fournisseur: oeConst })"
                  >{{ oeConst }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2 w-full h-full">
        <div class="flex flex-col space-y-4 w-full h-full">
          <!-- Tableau CROSS -->
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow-lg sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Ref.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Stock
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  ></th>
                </tr>
              </thead>
              <tbody class="bg-white text-sm">
                <div
                  v-show="loadingFile"
                  class="flex justify-center w-full bg-white items-center space-x-2 mt-4"
                >
                  <span>En cours de chargement</span>
                  <PulseLoader color="#2d74da" />
                </div>
                <!-- Les dispo -->
                <tr v-for="(sim, indexSim) in simTabOne" :key="indexSim">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex flex-col space-y-1">
                      <div class="flex flex-row items-center space-x-2">
                        <span>{{ sim.article.Ref_fournisseur }}</span>
                        <span
                          title="Plus d'informations"
                          class="material-icons-outlined text-blue-500 cursor-pointer"
                          @click="plusInfo(sim.article)"
                        >
                          info
                        </span>
                        <!-- <articleReplace
                        @replace="(data) => {setReplace(data, indexSim, sim)}"
                        :Ref_fournisseur="sim.article.Ref_fournisseur"
                      /> -->
                      </div>
                      <span>{{ sim.article.Code_marque }}</span>
                    </div>
                    <div v-if="articleReplace.length >= 1" class="">
                      <span
                        @click="showArticleReplace = !showArticleReplace"
                        v-if="articleReplace.length >= 1"
                        title="Réf. de remplacement"
                        class="material-icons-outlined relative text-orange-500 cursor-pointer"
                      >
                        published_with_changes
                      </span>
                      <div
                        v-show="showArticleReplace"
                        class="flex flex-col absolute z-20 bg-white drop-shadow-lg"
                      >
                        <div
                          title="Article de remplacement"
                          class="flex flex-row"
                          v-for="(art, index) in articleReplace"
                          :key="index"
                        >
                          <button
                            @click="changeReplace(indexSim, art)"
                            class="text-orange-500 text-center"
                          >
                            {{ art.Ref_fournisseur }}</button
                          ><br />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <span>{{ sim.article.Description }}</span>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <stockvue
                      :quantity="qtySimTabOne[indexSim]"
                      :Article="sim.article"
                      :key="sim.article._id"
                    />
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div
                      v-if="sim.article.Code_marque == 'MEYLE'"
                      class="flex flex-col space-y-1"
                    >
                      <prixNetArticleVue
                        :article="sim.article.Ref_complementaire"
                        :marque="sim.article.Code_marque"
                        :Prefixe_tarif="sim.article.Prefixe_tarif"
                        :qty="qtySimTabOne[indexSim]"
                        :key="sim.article._id"
                      />
                    </div>
                    <div v-else class="flex flex-col space-y-1">
                      <prixNetArticleVue
                        :article="sim.article.Ref_fournisseur"
                        :marque="sim.article.Code_marque"
                        :Prefixe_tarif="sim.article.Prefixe_tarif"
                        :qty="qtySimTabOne[indexSim]"
                        :key="sim.article._id"
                      />
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="relative m-auto -mt-4">
                      <Transition name="slide-fade2">
                        <button
                          title="Ajouté au panier"
                          v-if="showTransition[indexSim]"
                          class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                        >
                          add_task
                        </button>
                      </Transition>

                      <input
                        v-if="!showTransition[indexSim]"
                        type="Number"
                        @change="keySimTabOne = keySimTabOne + 1"
                        v-model="qtySimTabOne[indexSim]"
                        class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                      />

                      <Transition name="slide-fade">
                        <button v-if="!showTransition[indexSim]">
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-if="sim.article.Code_marque == 'MEYLE'"
                            @click="
                              handlerArticle(
                                {
                                  article: sim.article,
                                  stockData: sim.stock,
                                },
                                qtySimTabOne[indexSim]
                              ),
                                (showTransition[indexSim] =
                                  !showTransition[indexSim])
                            "
                          >
                            add_shopping_cart
                          </button>
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-else
                            @click="
                              handlerArticle(
                                {
                                  article: sim.article,
                                  stockData: sim.stock,
                                },
                                qtySimTabOne[indexSim]
                              ),
                                (showTransition[indexSim] =
                                  !showTransition[indexSim])
                            "
                          >
                            add_shopping_cart
                          </button>
                        </button>
                      </Transition>
                    </div>
                  </td>
                </tr>

                <!-- Référence alternative -->
                <tr v-for="(a, index) in articleRef" :key="index">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="flex flex-col text-left space-y-1">
                      <div class="flex flex-row items-center space-x-2">
                        <span> {{ a.Ref_fournisseur }}</span>

                        <span
                          title="Plus d'informations"
                          class="material-icons-outlined text-blue-500 cursor-pointer"
                          @click="plusInfo(a)"
                        >
                          info
                        </span>
                        <!-- <articleReplace 
                      :Ref_fournisseur="a.Ref_fournisseur" /> -->
                      </div>
                      <span class="m-0"> {{ a.Code_marque }} </span>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ a.Description }}
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <stockvue
                      :quantity="qtyArticleRef[index]"
                      :Article="a"
                      :key="keyArticleRef"
                    />
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div
                      v-if="a.Code_marque == 'MEYLE'"
                      class="flex flex-col space-y-1"
                    >
                      <prixNetArticleVue
                        :article="a.Ref_complementaire"
                        :marque="a.Code_marque"
                        :Prefixe_tarif="a.Prefixe_tarif"
                        :qty="qtyArticleRef[index]"
                        :key="keyArticleRef"
                      />
                    </div>
                    <div v-else class="flex flex-col space-y-1">
                      <prixNetArticleVue
                        :article="a.Ref_fournisseur"
                        :marque="a.Code_marque"
                        :Prefixe_tarif="a.Prefixe_tarif"
                        :qty="qtyArticleRef[index]"
                        :key="keyArticleRef"
                      />
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div class="relative m-auto -mt-4">
                      <Transition name="slide-fade2">
                        <button
                          title="Ajouté au panier"
                          v-if="showTransition2[index]"
                          class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                        >
                          add_task
                        </button>
                      </Transition>

                      <input
                        v-if="!showTransition2[index]"
                        type="Number"
                        @change="keyArticleRef = keyArticleRef + 1"
                        v-model="qtyArticleRef[index]"
                        class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                      />

                      <Transition name="slide-fade">
                        <button v-if="!showTransition2[index]">
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-if="a.Code_marque == 'MEYLE'"
                            @click="
                              handlerArticle(
                                {
                                  article: a,
                                  stockData: tabDispoAlter[index],
                                },
                                qtyArticleRef[index]
                              ),
                                (showTransition2[index] =
                                  !showTransition2[index])
                            "
                          >
                            add_shopping_cart
                          </button>
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-else
                            @click="
                              handlerArticle(
                                {
                                  article: a,
                                  stockData: tabDispoAlter[index],
                                },
                                qtyArticleRef[index]
                              ),
                                (showTransition2[index] =
                                  !showTransition2[index])
                            "
                          >
                            add_shopping_cart
                          </button>
                        </button>
                      </Transition>
                    </div>
                  </td>
                </tr>
                <!-- Les dispo filiale -->
                <tr
                  v-for="(simScd, indexSim) in simTab2"
                  :key="indexSim"
                  class="bg-blue-100"
                >
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="simScd.article.length > 0"
                  >
                    <div class="flex flex-col space-y-1">
                      <div class="flex flex-row items-center space-x-2">
                        <span>{{ simScd.article[0].Ref_fournisseur }}</span>
                        <span
                          title="Plus d'informations"
                          class="material-icons-outlined text-blue-500 cursor-pointer"
                          @click="plusInfo(simScd.article[0])"
                        >
                          info
                        </span>
                        <!-- <articleReplace
                        :Ref_fournisseur="simScd.article[0].Ref_fournisseur"
                      /> -->
                      </div>
                      <span>{{ simScd.article[0].Code_marque }}</span>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="simScd.article.length > 0"
                  >
                    <span>{{ simScd.article[0].Description }}</span>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="simScd.article.length > 0"
                  >
                    <stockvue
                      :quantity="qtySimTab2[indexSim]"
                      :Article="simScd.article[0]"
                      :key="keySimTab2"
                    />
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="simScd.article.length > 0"
                  >
                    <div
                      v-if="simScd.article[0].Code_marque == 'MEYLE'"
                      class="flex flex-col space-y-1"
                    >
                      <prixNetArticleVue
                        :article="simScd.article[0].Ref_complementaire"
                        :marque="simScd.article[0].Code_marque"
                        :Prefixe_tarif="simScd.article[0].Prefixe_tarif"
                        :qty="qtySimTab2[indexSim]"
                        :key="keySimTab2"
                      />
                    </div>
                    <div v-else class="flex flex-col space-y-1">
                      <prixNetArticleVue
                        :article="simScd.article[0].Ref_fournisseur"
                        :marque="simScd.article[0].Code_marque"
                        :Prefixe_tarif="simScd.article[0].Prefixe_tarif"
                        :qty="qtySimTab2[indexSim]"
                        :key="keySimTab2"
                      />
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="simScd.article.length > 0"
                  >
                    <div class="relative m-auto -mt-4">
                      <Transition name="slide-fade2">
                        <button
                          title="Ajouté au panier"
                          v-if="showTransition3[indexSim]"
                          class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                        >
                          add_task
                        </button>
                      </Transition>

                      <input
                        v-if="!showTransition3[indexSim]"
                        type="Number"
                        @change="keySimTab2 = keySimTab2 + 1"
                        v-model="qtySimTab2[indexSim]"
                        class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                      />

                      <Transition name="slide-fade">
                        <button v-if="!showTransition3[indexSim]">
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-if="simScd.Code_marque == 'MEYLE'"
                            @click="
                              handlerArticle(
                                {
                                  article: simScd.article[0],
                                  stockData: simScd.stock,
                                },
                                qtySimTab2[indexSim]
                              ),
                                (showTransition3[indexSim] =
                                  !showTransition3[indexSim])
                            "
                          >
                            add_shopping_cart
                          </button>
                          <button
                            title="Ajouter au panier"
                            v-else
                            @click="
                              handlerArticle(
                                {
                                  article: simScd.article[0],
                                  stockData: simScd.stock,
                                },
                                qtySimTab2[indexSim]
                              ),
                                (showTransition3[indexSim] =
                                  !showTransition3[indexSim])
                            "
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                          >
                            add_shopping_cart
                          </button>
                        </button>
                      </Transition>
                    </div>
                  </td>
                </tr>

                <!-- Les sur commandes -->
                <tr v-for="(simScd, indexSim) in tabSurCmd" :key="indexSim">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="tabPrixNet[indexSim] !== '--'"
                  >
                    <div class="flex flex-col space-y-1">
                      <div class="flex flex-row items-center space-x-2">
                        <span>{{ simScd.article.Ref_fournisseur }}</span>
                        <span
                          title="Plus d'informations"
                          class="material-icons-outlined text-blue-500 cursor-pointer"
                          @click="plusInfo(simScd.article)"
                        >
                          info
                        </span>
                        <!-- <articleReplace
                        :Ref_fournisseur="simScd.article.Ref_fournisseur"
                      /> -->
                      </div>
                      <span>{{ simScd.article.Code_marque }}</span>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="tabPrixNet[indexSim] !== '--'"
                  >
                    <span>{{ simScd.article.Description }}</span>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="tabPrixNet[indexSim] !== '--'"
                  >
                    <stockvue
                      :quantity="qtyTabSurCmd[indexSim]"
                      :Article="simScd.article"
                      :key="keyTabSurCmd"
                    />
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="tabPrixNet[indexSim] !== '--'"
                  >
                    <div
                      v-if="simScd.article.Code_marque == 'MEYLE'"
                      class="flex flex-col space-y-1"
                    >
                      <prixNetArticleVue
                        :article="simScd.article.Ref_complementaire"
                        :marque="simScd.article.Code_marque"
                        :Prefixe_tarif="simScd.article.Prefixe_tarif"
                        :qty="qtyTabSurCmd[indexSim]"
                        @prix="
                          (arg) => {
                            setPrixNet(arg, indexSim, simScd);
                          }
                        "
                        :key="keyTabSurCmd"
                      />
                    </div>
                    <div v-else class="flex flex-col space-y-1">
                      <prixNetArticleVue
                        :article="simScd.article.Ref_fournisseur"
                        :marque="simScd.article.Code_marque"
                        :Prefixe_tarif="simScd.article.Prefixe_tarif"
                        :qty="qtyTabSurCmd[indexSim]"
                        @prix="
                          (arg) => {
                            setPrixNet(arg, indexSim, simScd);
                          }
                        "
                        :key="keyTabSurCmd"
                      />
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    v-if="tabPrixNet[indexSim] !== '--'"
                  >
                    <div class="relative m-auto -mt-4">
                      <Transition name="slide-fade2">
                        <button
                          title="Ajouté au panier"
                          v-if="showTransition4[indexSim]"
                          class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                        >
                          add_task
                        </button>
                      </Transition>

                      <input
                        v-if="!showTransition4[indexSim]"
                        type="Number"
                        @change="keyTabSurCmd = keyTabSurCmd + 1"
                        v-model="qtyTabSurCmd[indexSim]"
                        class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                      />

                      <Transition name="slide-fade">
                        <button v-if="!showTransition4[indexSim]">
                          <button
                            title="Ajouter au panier"
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                            v-if="simScd.Code_marque == 'MEYLE'"
                            @click="
                              handlerArticle(
                                {
                                  article: simScd.article,
                                  stockData: simScd.stock,
                                },
                                qtyTabSurCmd[indexSim]
                              ),
                                (showTransition4[indexSim] =
                                  !showTransition4[indexSim])
                            "
                          >
                            add_shopping_cart
                          </button>
                          <button
                            title="Ajouter au panier"
                            v-else
                            @click="
                              handlerArticle(
                                {
                                  article: simScd.article,
                                  stockData: simScd.stock,
                                },
                                qtyTabSurCmd[indexSim]
                              ),
                                (showTransition4[indexSim] =
                                  !showTransition4[indexSim])
                            "
                            class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                          >
                            add_shopping_cart
                          </button>
                        </button>
                      </Transition>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fin tableau CROSS -->

          <!-- ref non trouvée -->

          <div
            class="overflow-hidden border-b border-gray-200 shadow-lg sm:rounded-lg bg-gray-50"
            v-if="
              tabSurCmd.length == 0 &&
              tabVoirPlus.length == 0 &&
              simTabOne.length == 0 &&
              simTab2.length == 0 &&
              !loadingFile
            "
          >
            <p
              class="text-xs font-medium leading-middle text-center text-rose-600 uppercase"
            >
              Aucune référence trouvéee !
            </p>
          </div>

          <!-- Fin ref non trouvée -->

          <!-- Tableau Voir Plus -->
          <div class="flex flex-col space-y-4" v-else>
            <div class="flex justify-center">
              <button
                @click="afficherPlus = !afficherPlus"
                v-if="!afficherPlus && !loadingFile"
                class="flex justify-between space-x-4 items-center w-44 text-white bg-green-500 hover:bg-green-600 py-1 px-4 shadow-lg rounded-md"
              >
                <p>Voir plus</p>
                <span class="material-icons-outlined" style="color: orange">
                  keyboard_double_arrow_down
                </span>
              </button>
              <button
                @click="afficherPlus = !afficherPlus"
                v-if="afficherPlus && !loadingFile"
                class="flex justify-between space-x-4 items-center w-44 text-white bg-green-500 hover:bg-green-600 py-1 px-4 shadow-lg rounded-md"
              >
                <p>Voir moins</p>
                <span class="material-icons-outlined" style="color: orange">
                  keyboard_double_arrow_up
                </span>
              </button>
            </div>
            <Transition v-show="afficherPlus">
              <table class="min-w-full rounded-lg">
                <tbody class="bg-white text-sm shadow-lg rounded-lg">
                  <!-- Voir plus -->
                  <tr v-for="(sim, indexSim) in tabVoirPlus" :key="indexSim">
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-col space-y-1">
                        <div class="flex flex-row items-center space-x-2">
                          <span>{{ sim.article.Ref_fournisseur }}</span>
                          <span
                            title="Plus d'informations"
                            class="material-icons-outlined text-blue-500 cursor-pointer"
                            @click="plusInfo(sim.article)"
                          >
                            info
                          </span>
                          <!-- <articleReplace
                          :Ref_fournisseur="sim.article.Ref_fournisseur"
                        /> -->
                        </div>
                        <span>{{ sim.article.Code_marque }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <span>{{ sim.article.Description }}</span>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <stockvue
                        :quantity="qtyTabVoirPlus[indexSim]"
                        :Article="sim.article"
                        :key="keyTabVoirPlus"
                      />
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div
                        v-if="sim.article.Code_marque == 'MEYLE'"
                        class="flex flex-col space-y-1"
                      >
                        <prixNetArticleVue
                          :article="sim.article.Ref_complementaire"
                          :marque="sim.article.Code_marque"
                          :Prefixe_tarif="sim.article.Prefixe_tarif"
                          :qty="qtyTabVoirPlus[indexSim]"
                          :key="keyTabVoirPlus"
                        />
                      </div>
                      <div v-else class="flex flex-col space-y-1">
                        <prixNetArticleVue
                          :article="sim.article.Ref_fournisseur"
                          :marque="sim.article.Code_marque"
                          :Prefixe_tarif="sim.article.Prefixe_tarif"
                          :qty="qtyTabVoirPlus[indexSim]"
                          :key="keyTabVoirPlus"
                        />
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="relative m-auto -mt-4">
                        <Transition name="slide-fade2">
                          <button
                            title="Ajouté au panier"
                            v-if="showTransition5[indexSim]"
                            class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                          >
                            add_task
                          </button>
                        </Transition>

                        <input
                          v-if="!showTransition5[indexSim]"
                          type="Number"
                          @change="keyTabVoirPlus = keyTabVoirPlus + 1"
                          v-model="qtyTabVoirPlus[indexSim]"
                          class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                        />

                        <Transition name="slide-fade">
                          <button v-if="!showTransition5[indexSim]">
                            <button
                              title="Ajouter au panier"
                              class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                              v-if="sim.article.Code_marque == 'MEYLE'"
                              @click="
                                handlerArticle(
                                  {
                                    article: sim.article,
                                    stockData: sim.stock,
                                  },
                                  qtyTabVoirPlus[indexSim]
                                ),
                                  (showTransition5[indexSim] =
                                    !showTransition5[indexSim])
                              "
                            >
                              add_shopping_cart
                            </button>
                            <button
                              title="Ajouter au panier"
                              class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                              v-else
                              @click="
                                handlerArticle(
                                  {
                                    article: sim.article,
                                    stockData: sim.stock,
                                  },
                                  qtyTabVoirPlus[indexSim]
                                ),
                                  (showTransition5[indexSim] =
                                    !showTransition5[indexSim])
                              "
                            >
                              add_shopping_cart
                            </button>
                          </button>
                        </Transition>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Transition>
          </div>
          <!-- Fin tableau Voir Plus -->
        </div>
        <div
          v-for="(crossOe, indexCrossOe) in tabCrossOE"
          :key="indexCrossOe"
          class="flex flex-col space-y-4 w-full h-full"
        >
          <div>{{ crossOe.oe.mfrName }} - {{ crossOe.oe.articleNumber }}</div>
          <div class="flex flex-col space-y-4 w-full h-full">
            <!-- Tableau CROSS -->
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow-lg sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Ref.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Stock
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    ></th>
                  </tr>
                </thead>
                <tbody class="bg-white text-sm">
                  <tr
                    v-for="(simOe, indexSim) in crossOe.articles"
                    :key="indexSim"
                  >
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-col space-y-1">
                        <div class="flex flex-row items-center space-x-2">
                          <span>{{ simOe.article.Ref_fournisseur }}</span>
                          <span
                            title="Plus d'informations"
                            class="material-icons-outlined text-blue-500 cursor-pointer"
                            @click="plusInfo(simOe.article)"
                          >
                            info
                          </span>
                        </div>
                        <span>{{ simOe.article.Code_marque }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <span>{{ simOe.article.Description }}</span>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <stockvue
                        :quantity="qtySimTabOne[indexSim]"
                        :Article="simOe.article"
                        :key="simOe.article._id"
                      />
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div
                        v-if="simOe.article.Code_marque == 'MEYLE'"
                        class="flex flex-col space-y-1"
                      >
                        <prixNetArticleVue
                          :article="simOe.article.Ref_complementaire"
                          :marque="simOe.article.Code_marque"
                          :Prefixe_tarif="simOe.article.Prefixe_tarif"
                          :qty="qtySimTabOne[indexSim]"
                          :key="simOe.article._id"
                        />
                      </div>
                      <div v-else class="flex flex-col space-y-1">
                        <prixNetArticleVue
                          :article="simOe.article.Ref_fournisseur"
                          :marque="simOe.article.Code_marque"
                          :Prefixe_tarif="simOe.article.Prefixe_tarif"
                          :qty="qtySimTabOne[indexSim]"
                          :key="simOe.article._id"
                        />
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="relative m-auto -mt-4">
                        <Transition name="slide-fade2">
                          <button
                            title="Ajouté au panier"
                            v-if="showTransition[indexSim]"
                            class="material-icons-round text-white bg-green-500 py-2 px-4 rounded-lg items-center"
                          >
                            add_task
                          </button>
                        </Transition>

                        <input
                          v-if="!showTransition[indexSim]"
                          type="Number"
                          @change="keySimTabOne = keySimTabOne + 1"
                          v-model="qtySimTabOne[indexSim]"
                          class="px-1 shadow bd-gray-400 w-12 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                        />

                        <Transition name="slide-fade">
                          <button v-if="!showTransition[indexSim]">
                            <button
                              title="Ajouter au panier"
                              class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                              v-if="simOe.article.Code_marque == 'MEYLE'"
                              @click="
                                handlerArticle(
                                  {
                                    article: simOe.article,
                                    stockData: simOe.stock,
                                  },
                                  qtySimTabOne[indexSim]
                                ),
                                  (showTransition[indexSim] =
                                    !showTransition[indexSim])
                              "
                            >
                              add_shopping_cart
                            </button>
                            <button
                              title="Ajouter au panier"
                              class="material-icons-round hover:bg-green-500 hover:text-white text-blue-500 py-2 px-4 rounded-lg items-center"
                              v-else
                              @click="
                                handlerArticle(
                                  {
                                    article: simOe.article,
                                    stockData: simOe.stock,
                                  },
                                  qtySimTabOne[indexSim]
                                ),
                                  (showTransition[indexSim] =
                                    !showTransition[indexSim])
                              "
                            >
                              add_shopping_cart
                            </button>
                          </button>
                        </Transition>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import stockvue from "@/components/Article/stock.vue";
import prixNetArticleVue from "@/components/Catalogue/prixNetArticle.vue";
import { useToast } from "vue-toastification";
import articleInfo from "../components/articleInfo/articleInfo.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article.ts";
import sideLoading from "@/components/Catalogue/sideLoading.vue";
import { MisePanier } from "@/hooks/statistiques/savedSearch.ts";
export default {
  data() {
    return {
      Ref_fournisseur: "p85020",
      simTabOne: [],
      articleRef: [],
      simTab2: [],
      openModalArticle: false,
      articleInfo_componentKey: 0,
      showTransition: [],
      showTransition2: [],
      showTransition3: [],
      showTransition4: [],
      showTransition5: [],
      loadingFile: false,
      tabPrixNet: [],
      afficherPlus: false,
      tabVoirPlus: [],
      tabDispo: [],
      tabDispoFiliale: [],
      tabSurCmd: [],
      filialeNoStock: [],
      qtySimTabOne: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      qtyArticleRef: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      qtySimTab2: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      qtyTabSurCmd: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      qtyTabVoirPlus: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      keySimTabOne: 0,
      keyArticleRef: 0,
      keySimTab2: 0,
      keyTabSurCmd: 0,
      keyTabVoirPlus: 0,
      tabDispoAlter: [],
      brandList: [],
      isLoading: false,
      brandSelected: [],
      groupSelected: [],
      simTabOneSave: [],
      tabSurCmdSave: [],
      articleRefSave: [],
      simTab2Save: [],
      tabVoirPlusSave: [],
      data: [],
      index: 0,
      articleReplace: [],
      showArticleReplace: false,
      groupList: [],
      savedSearch: "",
      tabConstructeur: [],
      showConstructeur: [],
      tabCrossOE: [],
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    stockvue,
    prixNetArticleVue,
    articleInfo,
    PulseLoader,
    sideLoading,
  },
  computed: {
    ...mapGetters([
      "user",
      "Ref_fournisseur_search",
      "actionArticle",
      "tabClient",
      "plateform",
    ]),
  },
  methods: {
    ...mapActions([
      "searchTranslatedDirectAdd",
      "setArticleInfo",
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "searchRefFournisseur",
    ]),
    async searchArticleRef() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/articlecross/searchArticleRef`, {
          Ref_fournisseur: this.Ref_fournisseur_search,
          plateform: plateform_mere,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.articleRef = response.data[0].Cross;
            this.articleRefSave = this.articleRef;
          }
        })
        .catch(() => {
          this.articleRef = [];
        });
    },
    async searchArticleSim() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      this.loadingFile = true;
      this.isLoading = true;
      await axios
        .post(`${process.env.VUE_APP_API}/searchEngine/searchArticleSim`, {
          Ref_fournisseur: this.Ref_fournisseur_search,
          plateform: plateform_mere,
          Client: this.user.proprietaire,
          User: this.user.username,
        })
        .then((res) => {
          this.simTabOne = res.data.articleEnStock;
          this.simTabOneSave = this.simTabOne;
          this.tabSurCmd = res.data.articleNoStock;
          this.tabSurCmdSave = this.tabSurCmd;
          this.loadingFile = false;
          this.savedSearch = res.data.savedSearch;
          this.isLoading = false;
          this.tabConstructeur = res.data.oeSort;
          this.tabCrossOE = res.data.oeSim;
          this.getBrandList();
        })
        .catch(() => {
          this.simTabOne = [];
          this.tabSurCmd = [];
          this.loadingFile = false;
        });
    },
    async getRefSimilaire() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: this.Ref_fournisseur_search,
          plateform: plateform_mere,
        })
        .then((response) => {
          this.simTab2 = response.data;
          this.simTab2Save = this.simTab2;
        })
        .catch(() => {
          this.simTab2 = [];
        });
    },
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    setDispoAlter(data, index) {
      this.tabDispoAlter[index] = [{ stock: data }];
    },
    changeReplace(index, newArt) {
      this.simTabOne.splice(index, 1, { article: newArt, stockData: [] });
    },
    setPrixNet(data, index, line) {
      this.tabPrixNet[index] = data;
      if (data == "--") {
        this.tabVoirPlus.push(line);
      }
    },
    getBrandList() {
      this.brandList = [];
      this.groupList = [];
      for (const art of this.simTabOne) {
        if (!this.brandList.includes(art.article.Code_marque)) {
          this.brandList.push(art.article.Code_marque);
        }
        if (!this.groupList.includes(art.article.Description)) {
          this.groupList.push(art.article.Description);
        }
      }
      for (const art of this.articleRef) {
        if (!this.brandList.includes(art.Code_marque)) {
          this.brandList.push(art.Code_marque);
        }
        if (!this.groupList.includes(art.Description)) {
          this.groupList.push(art.Description);
        }
      }
      for (const art of this.simTab2) {
        if (!this.brandList.includes(art.article[0].Code_marque)) {
          this.brandList.push(art.article[0].Code_marque);
        }
        if (!this.groupList.includes(art.article[0].Description)) {
          this.groupList.push(art.article[0].Description);
        }
      }
      for (const art of this.tabSurCmd) {
        if (!this.brandList.includes(art.article.Code_marque)) {
          this.brandList.push(art.article.Code_marque);
        }
        if (!this.groupList.includes(art.article.Description)) {
          this.groupList.push(art.article.Description);
        }
      }
      for (const art of this.tabVoirPlus) {
        if (!this.brandList.includes(art.article.Code_marque)) {
          this.brandList.push(art.article.Code_marque);
        }
        if (!this.groupList.includes(art.article.Description)) {
          this.groupList.push(art.article.Description);
        }
      }
    },
    sortByBrand() {
      if (this.brandSelected.length === 0) {
        this.simTabOne = this.simTabOneSave;
        this.articleRef = this.articleRefSave;
        this.simTab2 = this.simTab2Save;
        this.tabSurCmd = this.tabSurCmdSave;
        this.tabVoirPlus = this.tabVoirPlusSave;
      } else {
        this.simTabOne = this.simTabOneSave.filter((item) =>
          this.brandSelected.includes(item.article.Code_marque)
        );
        this.articleRef = this.articleRefSave.filter((item) =>
          this.brandSelected.includes(item.Code_marque)
        );
        this.simTab2 = this.simTab2Save.filter((item) =>
          this.brandSelected.includes(item.article[0].Code_marque)
        );
        this.tabSurCmd = this.tabSurCmdSave.filter((item) =>
          this.brandSelected.includes(item.article.Code_marque)
        );
        this.tabVoirPlus = this.tabVoirPlusSave.filter((item) =>
          this.brandSelected.includes(item.article.Code_marque)
        );
      }
    },
    sortByGroup() {
      if (this.groupSelected.length === 0) {
        this.simTabOne = this.simTabOneSave;
        this.articleRef = this.articleRefSave;
        this.simTab2 = this.simTab2Save;
        this.tabSurCmd = this.tabSurCmdSave;
        this.tabVoirPlus = this.tabVoirPlusSave;
      } else {
        this.simTabOne = this.simTabOneSave.filter((item) =>
          this.groupSelected.includes(item.article.Description)
        );
        this.articleRef = this.articleRefSave.filter((item) =>
          this.groupSelected.includes(item.Description)
        );
        this.simTab2 = this.simTab2Save.filter((item) =>
          this.groupSelected.includes(item.article[0].Description)
        );
        this.tabSurCmd = this.tabSurCmdSave.filter((item) =>
          this.groupSelected.includes(item.article.Description)
        );
        this.tabVoirPlus = this.tabVoirPlusSave.filter((item) =>
          this.groupSelected.includes(item.article.Description)
        );
      }
    },
    async handlerArticle(structure, qtyToPanier) {
      var existArticle = false;
      await MisePanier(this.savedSearch, structure.article.Ref_fournisseur);
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) +
            parseInt(qtyToPanier);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticleClient(
          structure,
          plateform_mere,
          this.user.proprietaire,
          this.tabClient.Group.Name,
          parseInt(qtyToPanier)
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);
      }
      this.success();
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        // if (this.user.proprietaire.dsvCheck == false) {
        this.addactionArticle({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
      } else {
        this.adddatanonconsigne(dataConsigne);
      }
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async searchArticleReplace() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/articlecross/searchArticleRef`, {
          Ref_fournisseur: this.Ref_fournisseur_search,
          plateform: plateform_mere,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.articleReplace = res.data[0].Replace;
            // this.articleReplace.forEach((element) => {
            //   if(element == "Ref_fournisseur") {
            //     this.articleRef = element;
            //   }
            // })
          }
        });
    },
  },
  mounted() {
    this.getRefSimilaire();
    this.searchArticleSim();
    this.searchArticleRef();
    this.searchArticleReplace();
  },
};
</script>

<style>
.slide-fade2-enter-active {
  transition: all 0.3s ease-out;
  transition-delay: 0.3s;
}
.slide-fade2-enter-from {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
